import React from 'react'
import { Helmet } from 'react-helmet'
import { StaticImage } from 'gatsby-plugin-image'
import Theme, { Container, Layout } from '../components'

const AppointmentCheckoutPage = (): React.ReactElement => (
  <>
    <Helmet>
      <script
        async
        defer
        type="text/javascript"
        src="https://static.hsappstatic.net/MeetingsEmbed/ex/MeetingsEmbedCode.js"
      />
    </Helmet>
    <Theme>
      <div className="fixed z-0 w-full h-full top-0 left-0 opacity-10">
        <StaticImage
          src="../images/fish.jpg"
          alt="Fische"
          objectFit="cover"
          className="w-full h-full select-none"
          draggable={false}
        />
      </div>
      <Layout>
        <Container className="">
          <div
            className="meetings-iframe-container"
            data-src="https://meetings.hubspot.com/mathias-born?embed=true"
          />
        </Container>
      </Layout>
    </Theme>
  </>
)

export default AppointmentCheckoutPage
